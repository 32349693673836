.contenedor-aviso {
  color: white; /* Cambia el color de todo el texto a blanco */
  margin: 20px; /* Añade margen alrededor del contenedor */
  padding: 20px; /* Añade espacio interior para mayor separación del contenido */
  background-color: #0F787E; /* Opcional: cambia el fondo del contenedor si lo deseas */
  border-radius: 10px; /* Añade bordes redondeados al contenedor */
}

.title-aviso {
  color: white; /* Asegúrate de que el título también sea blanco */
  text-align: center; /* Centra el título */
  margin-bottom: 15px; /* Añade margen inferior al título */
}

