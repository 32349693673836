html, body{
  height: 100%;
}

.dashboard {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #0F787E;
    padding: 20px;
    border-right: 1px solid #19A7AF;
    color: #fff;
    height: 100%;
  }
  
  h3 {
    margin-bottom: 20px;
  }

  
  .search {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #19A7AF;
    border-radius: 4px;
    background-color: #0a5458;
  }
  
  .user-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #0a5458;
    border: 1px solid #19A7AF;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .user-card.active {
    background-color: #19A7AF;
  }
  
  .user-card:hover {
    background-color: #19A7AF;
  }
  
  .user-name {
    font-weight: bold;
    color: black;
  }

  .user-role {
    color: black;
  }

  
  .user-balance {
    margin-top: 5px;
    color: black;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    background-color: #0a5458;
  }
  
  .user-details {
    background-color: #0F787E;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Contenedor principal para las tarjetas KPI */
.kpis-container {
  display: flex;
  justify-content: space-between; /* Espacio entre las tarjetas */
  flex-wrap: wrap; /* Permitir que las tarjetas se acomoden en varias filas si es necesario */
  margin-bottom: 20px; /* Espacio entre las tarjetas y el resto del contenido */
}

/* Estilo para cada tarjeta KPI */
.kpi-card {
  flex: 1; /* Hacer que cada tarjeta ocupe el mismo espacio */
  margin: 10px; /* Espacio entre tarjetas */
  background-color: #0F787E;
  border: 1px solid #19A7AF;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 250px; /* Tamaño mínimo de las tarjetas para evitar que se encojan demasiado */
  transition: transform 0.3s ease;
}

.kpi-card-dash {
  flex: 1; /* Hacer que cada tarjeta ocupe el mismo espacio */
  margin: 10px; /* Espacio entre tarjetas */
  background-color: #19A7AF;
  border: 1px solid #19A7AF;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 250px; /* Tamaño mínimo de las tarjetas para evitar que se encojan demasiado */
  transition: transform 0.3s ease;
}

/* Estilo para los valores dentro de las tarjetas */
.kpi-content {
  margin-top: 10px;
}

.kpi-value {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

/* Para pantallas más pequeñas (tablets) */
@media (max-width: 768px) {
  .kpis-container {
    flex-direction: column; /* Las tarjetas se apilan verticalmente en pantallas pequeñas */
    align-items: center;
  }

  .kpi-card {
    width: 100%; /* Cada tarjeta ocupará todo el ancho disponible */
  }
}

/* Para pantallas de dispositivos móviles */
@media (max-width: 480px) {
  .kpi-card {
    padding: 15px;
    font-size: 16px;
  }

  .kpi-value {
    font-size: 20px;
  }
}

.kpis-container-graphics {
  display: flex;                /* Utiliza flexbox para alinear en fila */
  flex-wrap: wrap;              /* Permite que las gráficas se envuelvan si el espacio no es suficiente */
  justify-content: space-around; /* Distribuye el espacio alrededor de las gráficas */
  align-items: center;          /* Alinea verticalmente las gráficas al centro */
  gap: 20px;                    /* Espacio entre las gráficas */
  flex-direction: row;
  background-color: #0F787E;
  padding: 20px;
}

.chart-container {
  flex: 1 1 calc(33.333% - 40px);             /* Cada gráfico ocupará el mismo espacio mínimo de 300px */
  padding: 10px;
  background-color: #19A7AF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;      /* Centrar el gráfico dentro del contenedor */
  align-items: center;
  flex-direction: row;
  width: 30vh;
  height: 30vh;
}

.contenedor-dashboard{
  background-color: #0F787E;
}

