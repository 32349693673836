/* From Uiverse.io by Praashoo7 */ 
.card-modelos {
    width: 300px; /* Establece el ancho fijo de la tarjeta */
    margin-bottom: 2rem; /* Espacio entre filas */
    position: relative;
    height: 28em;
    background: #0F787E;
    transition: .4s ease-in-out;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    overflow: hidden;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.main-modelos {
    display: flex;
    flex-wrap: wrap; /* Permite que las tarjetas se vayan a la siguiente fila */
    justify-content: center; /* Centra horizontalmente las tarjetas */
    align-items: center; /* Centra verticalmente las tarjetas */
    gap: 1rem; /* Espacio entre las tarjetas */
    padding: 2rem; /* Espacio interno para asegurar que no se peguen al borde */
}
  
  .heading {
    position: relative;
    color: black;
    font-weight: bold;
    font-size: 1.1em;
    padding-top: 1em;
    transition: .4s ease-in-out;
  }
  
  .details {
    position: relative;
    color: black;
    font-size: 0.6em;
    padding-top: 1.5em;
    padding-left: 2em;
    transition: .4s ease-in-out;
  }
  
  .price {
    position: relative;
    color: black;
    font-weight: bold;
    font-size: 0.8em;
    padding-top: 1.5em;
    padding-left: 1.5em;
    top: 9.6em;
    transition: .4s ease-in-out;
  }
  
  .btn1 {
    position: relative;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    font-size: 0.6em;
    width: 90%;
    height: 3.5vh;
    border-radius: 10px;
    top: 14.8em;
    transition: .4s ease-in-out;
    font-weight: bold;
  }
  
  .btn1:hover {
    background-color: #19A7AF;
    cursor: pointer;
  }
  
  .btn2 {
    position: relative;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    font-size: 0.6em;
    width: 90%;
    height: 3.5vh;
    border-radius: 10px;
    top: 15.5em;
    transition: .4s ease-in-out;
    font-weight: bold;
  }
  
  .btn2:hover {
    background-color: #19A7AF;
    cursor: pointer;
  }
  
  .glasses {
    position: relative;
    top: -8vh;
    left: -7vh;
    width: 70px;
    height: 70px;
    transition: .4s ease-in-out;
  }
  
  .card-modelos:hover + .glasses {
    transform: rotateX(360deg);
    height: 100px;
    width: 100px;
    left: -22vh;
    top: -27vh;
  }
  
  .card-modelos:hover .heading {
    transform: translateY(1em);
  }
