.desarrollo-item-title h1{
    color: black;
    font-size: 150%;
  }
  
  .desarrollo-item-title h2{
    color: black;
    font-size: 300%;
    font-family: sans-serif;
  }
  
  .containerFilosofia {
    display: grid;
    grid-template-areas: 
      "a b"
      "a c";
    grid-template-columns: 1fr 1fr; /* Filosofía ocupa el doble de espacio horizontal */
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .containerFilosofia {
      grid-template-areas: 
        "a"
        "b"
        "c";
      grid-template-columns: 1fr; /* Para pantallas pequeñas, las columnas colapsan */
    }
  }
  
  .filosofia {
    grid-area: a;
    background: #0F787E;
    padding: 25px 20px;
    border: 4px solid #19A7AF;
    border-radius: 10px;
    text-align: justify;
    color: #fff;
    font-family: "Poppins", sans-serif;
    transition: all 0.3s ease;
  }
  
  .vision, .mision {
    background: #0F787E;
    padding: 25px 20px;
    border: 4px solid #19A7AF;
    border-radius: 10px;
    text-align: justify;
    color: #fff;
    font-family: "Poppins", sans-serif;
    transition: all 0.3s ease;
  }
  
  .vision {
    grid-area: b;
    height: 100%; /* Hacemos que la altura sea automática */
  }
  
  .mision {
    grid-area: c;
    height: 100%; /* Hacemos que la altura sea automática */
  }
  
  .filosofia:hover,
  .vision:hover,
  .mision:hover {
    transform: translateY(-10px);
  }
  
  .TextFilosofia:before {
    content: " ";
    display: block;
    width: 100%;
    height: 2px;
    margin: 20px 0;
    background: #19A7AF;
  }
  
  .filosofia img {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    margin-top: 30%;
    margin-left: 18%;
  }
  

  .plataformasContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #19A7AF;
    border-radius: 20px;
  }
  
  .Plataformas {
    display: grid;
    margin: 1.5em;
    padding: 0;
  }
  
  .Plataformas .tec {
    list-style: none;
    margin: 2em 1.7em;
    position: relative; 
  }
  
  .Plataformas .tec p{
    pointer-events: none;
  }
  
  .Plataformas .tec .TextSector {
    position: absolute;
    top: 105%;
    left: 50%; 
    transform: translateX(-50%);
  }
  
  .Plataformas .tec .SectorImagen {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 0%;
  }
  
  .Plataformas .tec {
    text-decoration: none;
    width: 8em;
    height: 8em;
    background: #0F787E;
    transition-duration: .5s;
    box-shadow: -20px 20px 10px rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .Plataformas .tec:hover {
    transform: translate(20px,-15px);
    box-shadow: -50px 50px 50px rgba(0,0,0,.5);
    color: #fff;
    background: #19A7AF;
  }