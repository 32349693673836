@import url(//fonts.googleapis.com/css?family=Poppins:300:400);

.Titulos{
  text-align: center;
  color: #fff;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 2.5em;
  padding-top: 1em;
}

.container-valor {
  width: 100%;  
  height: 100%;
  position: relative;
}

.valor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrar los elementos */
  gap: 5%; /* Espacio entre los elementos */
}

.valor-empresarial {
  width: 100%; /* Que ocupe todo el espacio disponible */
  max-width: 380px; /* Máximo ancho para evitar que se extienda demasiado */
  padding-top: 25px;
  padding-bottom: 25px;
  background: #0F787E;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  color: #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
  padding: 22px;
  margin: 15px;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box; /* Asegura que padding y border no aumenten el tamaño total */
  font-size: 25px;
}

.IconValor {
  width: 7em;
  height: 7em;
  margin: 1em;
}

.valor-empresarial:hover {
  transform: translateY(-10px);
}

.text-valor:before {
  content: " ";
  display: block;
  width: 100%;
  height: 4px;
  margin: 20px 0;
  background: #19A7AF;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .valor {
    flex-direction: column; /* Cambia la dirección a columna para pantallas pequeñas */
    align-items: center;
    justify-content: center;
  }

  .valor-empresarial {
    width: 90%; /* Ajusta el tamaño para dispositivos pequeños */
  }
}

.inner-header {
  display: flex;
  flex-direction: column; /* Esto asegura que los elementos se apilen verticalmente */
  align-items: flex-start; /* Alinea el contenido al inicio (izquierda) */
  justify-content: center; /* Centra verticalmente los elementos */
}

.title-wave {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 20px; /* Puedes ajustar el espaciado entre el h1 y el párrafo */
}

p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px; /* Reduce el espaciado de letras para mejorar legibilidad */
  font-size: 16px; /* Ajusta el tamaño del texto para mejorar legibilidad */
  color: #ffffff;
  max-width: 600px; /* Limita el ancho del párrafo para evitar que se extienda demasiado */
  text-align: justify; /* Opcional: para que el texto esté justificado */
}

.inner-header {
  height:40vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}


.ContainerVentajas{
  width: 100%;  
  height: 100%;
  position: relative;
}

.ventajas {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.VentajasImagen{
  width: 8em;
  height: 8em;
  margin: 10px;
  z-index: 9;
}

.cardVentajas {
  --bg-color: #0F787E;
  --bg-color-light: #19A7AF;
  --text-color-hover: #fff;
  --box-shadow-color: #0A5458;
}

.cardVentajas {
  width: 19em;
  height: 75vh;
  background: #19A7AF;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  margin: 0 auto;
  margin-bottom: 1em;
}

.cardVentajas:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.titleVentajas{
  z-index: 1;
  color: #fff;
  font-size: 20px;
}

.cardVentajas:hover .overlayVentajas {
  transform: scale(10) translateZ(0);
  opacity: 1;
}

.cardVentajas:hover .circlecards {
  border-color: var(--bg-color-light);
  background: #0F787E;
}

.cardVentajas:hover .circlecards:after {
  background: var(--bg-color-light);
}

.cardVentajas:hover .textVentajas {
  color: var(--text-color-hover);
}

.cardVentajas:hover .titleVentajas {
  color: #000;
}

.cardVentajas .textVentajas {
  font-size: 17px;
  margin-top: 30px;
  z-index: 1;
  transition: color 0.3s ease-out;
  padding: 20px;
}

.circlecards {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #0F787E;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  margin: 10px;
}

.circlecards:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: #0F787E;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.overlayVentajas {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: #0F787E;
  z-index: 0;
  justify-content: center;
  align-items: center;
  margin-top: -125%;
  transition: transform 0.3s ease-out;
  opacity: 0;
}

.metodologia-bento-row{
    width: 100%;
    border-radius: 20px;
    grid-template-areas: 
    "a b b c"
    "a d d c"
    "e d d f"
    "e g g f";
    display: grid;
    position: relative;
    overflow: hidden;
    background-color: #0F787E;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
  .metodologia-bento-row {
      grid-template-areas: 
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g";
  }
}

@media (min-width: 769px) {
  .metodologia-bento-row {
      grid-template-areas: 
      "a b b c"
      "a d d c"
      "e d d f"
      "e g g f";
  }
}

.Arquitecto {
  width: 100%;
  height: 100%;
  margin-bottom: -40%;
  border-radius: 20px;
}

.bento-item-metodologia:nth-child(1){
  grid-area: a;
}

.bento-item-diagnostico:nth-child(2){
  grid-area: b;
}

.bento-item-diseño:nth-child(3){
  grid-area: c;
}

.bento-item-imagen:nth-child(4){
  grid-area: d;
}

.bento-item-desarrollo:nth-child(5){
  grid-area: e;
}

.bento-item-capacitacion:nth-child(6){
  grid-area: g;
}

.bento-item-metodologia,
.bento-item-diagnostico,
.bento-item-diseño,
.bento-item-imagen,
.bento-item-desarrollo,
.bento-item-capacitacion,
.bento-item-lanzamiento{
  background-color: #19A7AF;
  z-index: 1;
  margin: 10px;
  text-align: justify;
  padding: 15px;
  border-radius: 20px;
}

.bento-item-lanzamiento{
  grid-area: f;
}