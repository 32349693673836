/* From Uiverse.io by alexruix */ 
.card-curso {
    width: 190px;
    height: 254px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
   }
   
   .card-curso-details {
    color: black;
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
   }
   
   .card-curso-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
   }
   
   /*Hover*/
   .card-curso:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
   
   .card-curso:hover .card-curso-button {
    transform: translate(-50%, 50%);
    opacity: 1;
   }