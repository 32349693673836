:root {
  --m: 4rem;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

footer {
  margin-top: 3vh;
  z-index: 500;
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 5rem 2vw;
  position: relative;
}

footer::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
      rgba(15, 120, 126, 0) 5%,
      rgba(15, 120, 126, 0.3) 20%,
      rgba(15, 120, 126, 0.6) 30%,
      rgba(15, 120, 126, 0.8) 40%,
      rgba(15, 120, 126, 1) 50%,
      rgb(15, 120, 126)
  );
  z-index: -7;
}

.backdrop {
  z-index: -5;
  position: absolute;
  inset: 0;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  mask-image: linear-gradient(
      rgba(15, 120, 126, 0) 5%,
      rgba(15, 120, 126, 0.5) 10%,
      rgba(15, 120, 126, 0.8) 20%,
      rgba(15, 120, 126, 1) 30%,
      rgb(15, 120, 126)
  );
  -webkit-mask-image: linear-gradient(
      rgba(15, 120, 126, 0) 5%,
      rgba(15, 120, 126, 0.5) 10%,
      rgba(15, 120, 126, 0.8) 20%,
      rgba(15, 120, 126, 1) 30%,
      rgb(15, 120, 126)
  );
}

.col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: calc(0.3 * var(--m)) calc(0.8 * var(--m));
  width: 28%;
}

.col2, .col3 {
  background-color: #19A7AF;
  border-radius: calc(0.5 * var(--m));
  color: #fff;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 1000px) {
  :root {
      --m: 3rem;
  }
}

@media screen and (max-width: 700px) {
  footer {
      flex-direction: column;
      padding: 5rem 20vw;
  }
  .col {
      width: 100%;
  }
}


/* From Uiverse.io by MijailVillegas */ 
.card {
  border-radius: 15px;
  display: inline-block;
  padding: 10px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  backdrop-filter: blur(15px);
  transition: 0.5s;
  background-color: #19A7AF;
}

.card:hover {
  animation: ease-out 5s;
}

.card ul {
  padding: 1rem;
  display: flex;
  list-style: none;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.card ul li {
  cursor: pointer;
}

.svg {
  transition: all 0.3s;
  /* if you find some problems change w - h : 30px*/
  padding: 1rem;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  color: #0F787E;
  fill: currentColor;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3),
    inset 0 0 5px rgba(255, 255, 255, 0.5), 0 5px 5px rgba(0, 0, 0, 0.164);
}

.text {
  opacity: 0;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s;
  color: #0F787E;
  position: absolute;
  z-index: 9999;
}

/*isometric prooyection*/
.iso-pro {
  transition: 0.5s;
}
.iso-pro:hover a > .svg {
  transform: translate(15px, -15px);
  border-radius: 100%;
}

.iso-pro:hover .text {
  opacity: 1;
  transform: translate(10px, -2px) skew(-5deg);
}

.iso-pro:hover .svg {
  transform: translate(5px, -5px);
}

.iso-pro span {
  opacity: 0;
  position: absolute;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3),
    inset 0 0 5px rgba(255, 255, 255, 0.5), 0 5px 5px rgba(0, 0, 0, 0.164);
  border-radius: 50%;
  transition: all 0.3s;
  height: 60px;
  width: 60px;
}

.iso-pro:hover span {
  opacity: 1;
}

.iso-pro:hover span:nth-child(1) {
  opacity: 0.2;
}

.iso-pro:hover span:nth-child(2) {
  opacity: 0.4;
  transform: translate(5px, -5px);
}

.iso-pro:hover span:nth-child(3) {
  opacity: 0.6;
  transform: translate(10px, -10px);
}
