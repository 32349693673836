.desarrollo-item-title h1{
  color: black;
  font-size: 150%;
}

.TituloService{
  text-align: center;
  font-size: 1.5em;
  position: absolute;
  top: 2.6em;
  left: 0;
  right: 0;
  color: #fff;
  font-family: sans-serif;
  font-weight: bold;
  margin: 2em;
}

.desarrollo-item-title h2{
  color: black;
  font-size: 300%;
  font-family: sans-serif;
}

.container-desarrollo {
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  grid-template-areas: 
  "a b"
  "a c"
  ;
  display: grid;
  gap: 1rem;
}

@media (max-width: 768px) {
  .container-desarrollo {
      grid-template-areas: 
      "a"
      "b"
      "c";
  }
  .CardNosotros:hover .overlay {
    transform: scale(15) translateZ(0);
    background: #0F787E;
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .metodologia-bento-row {
      grid-template-areas: 
      "a b"
      "a c";
  }
  .CardNosotros:hover .overlay {
    transform: scale(15) translateZ(0);
    background: #0F787E;
    opacity: 1;
  }
}

.desarrollo-item-principal,
.desarrollo-facilitar,
.desarrollo-tecnologias{
  grid-area: a;
  background: #0F787E;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 4px solid #19A7AF;
  border-radius: 10px;
  text-align: justify;
  color: #fff;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
}

.desarrollo-item-principal:hover,
.desarrollo-facilitar:hover,
.desarrollo-tecnologias:hover{
  transform: translateY(-10px);
}

.TextDesarrollo:before {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background: #19A7AF;
}

.desarrollo-facilitar{
  grid-area: b;
}

.desarrollo-tecnologias{
  grid-area: c;
}

.ContainerNosotros{
  width: 100%;  
  height: 100%;
  position: relative;
  padding: 20px;
}

.Nosotros {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  text-align: justify;
}

.CardNosotros {
  --bg-color: #0F787E;
  --bg-color-light: #19A7AF;
  --text-color-hover: #fff;
  --box-shadow-color: #0A5458;
}

.CardNosotros {
  width: 22em;
  background: #19A7AF;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  margin: 0 auto;
  margin-bottom: 1em;
}

.CardNosotros:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.title{
  z-index: 1;
  color: #fff;
}

.CardNosotros:hover .circle {
  border-color: var(--bg-color-light);
  background: #0F787E;
}

.CardNosotros:hover .circle:after {
  background: var(--bg-color-light);
}

.CardNosotros:hover .textNosotros {
  color: var(--text-color-hover);
}

.CardNosotros:hover .title {
  color: #000;
}

.NosotrosImagen{
  border-radius: 50%;
  width: 8em;
  height: 8em;
  margin: 10px;
  z-index: 9;
}

.CardNosotros .textNosotros {
  font-size: 17px;
  color: #000;
  margin-top: 30px;
  z-index: 1;
  transition: color 0.3s ease-out;
  padding: 20px;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #0F787E;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  margin: 10px;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: #0F787E;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: #0F787E;
  z-index: 0;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out;
  opacity: 0;
}

@media (max-width: 768px) {
  .overlay {
      margin-top: -165%;;
}
}

@media (min-width: 769px) {
  .overlay {
      margin-top: -165%;
  }
}

.bento-services-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.bento-services {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 30%;
  height: 60vh;
  padding: 70px;
  margin: 10px auto;
  background: #0f787e;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .bento-services {
    width: 90%;
  }
}

@media (min-width: 769px) {
  .bento-services {
    width: 30%;
  }
}

.bento-services-service{
  position: relative;
  width: 100%;
  height: 11em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  background-color: #19a7af;
  border-radius: 15px;
  opacity: 1;
  transition: opacity 0.5s;
}

.tituloServicios{
  color: #fff;
  margin: 15px;
  text-align: center;
  font-size: 30px;
}

.descripcion{
  color: #fff;
  margin: 15px;
  text-align: justify;
  font-size: 25px;
}

.bento-item-service {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: bold;
  background-color: #19A7AF;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 0.5s;
  display: grid;
}

.bento-services img{
  width: 5rem;
  height: 5rem;
}

.bento-services::before,
.bento-services::after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  background-color: #19A7AF;
  transition: all 0.5s;
}

.bento-services::before {
  top: 0;
  right: 0;
  border-radius: 0 15px 0 100%;
}

.bento-services::after {
  bottom: 0;
  left: 0;
  border-radius: 0 100% 0 15px;
}

.bento-services:hover .bento-services-service {
  opacity: 0;
}

.bento-services:hover .bento-item-service {
  opacity: 1;
  z-index: 3;
}

.bento-services:hover::before,
.bento-services:hover::after {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transition: all 0.5s;
}

.bento-item p {
  font-size: 1.5rem;
  line-height: 1.5;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  width: 90%;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
 }
 
 button.learn-more {
  width: 100%;
  height: auto;
  padding: 7px;
 }
 
 button.learn-more .circleServices {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 2rem;
  background: #282936;
  border-radius: 1.625rem;
 }
 
 button.learn-more .circleServices .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 button.learn-more .circleServices .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 button.learn-more .circleServices .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.3rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
 }
 
 button:hover .circleServices {
  width: 100%;
 }
 
 button:hover .circleServices .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
 }
 
 button:hover .button-text {
  color: #fff;
 }

.sectoresContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #19A7AF;
  padding: 40px;
  border-radius: 20px;
}

.sectores {
  display: grid;
  margin: 1em;
  padding-bottom: 2.5em;
}

.sectores .sector {
  list-style: none;
  margin: 2.5em 1.8em;
  position: relative; 
}

.sectores .sector img{
  pointer-events: none;
}

.sectores .sector .TextSector {
  position: absolute;
  top: -30%;
  left: 50%; 
  transform: translateX(-50%);
  font-size: 30px;
}

.sectores .sector .SectorImagen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
}

.sectores .sector {
  text-decoration: none;
  width: 9em;
  height: 9em;
  background: #0F787E;
  border-radius: 50%;
  transition-duration: .5s;
  box-shadow: -20px 20px 10px rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectores .sector:hover {
  transform: translate(20px,-15px);
  box-shadow: -50px 50px 50px rgba(0,0,0,.5);
  color: #fff;
  background: #19A7AF;
}

.complemento-bento-row{
  width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: #19A7AF;
  padding: 2%;
  grid-template-areas: 
  "a a b"
  "a a c "
  "d e e"
  ;
  display: grid;
  gap: 1rem;
}

@media (max-width: 768px) {
  .complemento-bento-row {
      grid-template-areas: 
      "a"
      "b"
      "c"
      "d"
      "e";
  }
}

@media (min-width: 769px) {
  .complemento-bento-row {
      grid-template-areas: 
      "a a b"
      "a a c"
      "d e e";
  }
}

.complemento-item:nth-child(1){
  grid-area: a;
  background-color: #0F787E;
  place-items: center;
  width: 83.5%;
}

.complemento{
  height: 100%;
  width: 116%;
}

.complemento-mantenimiento:nth-child(2){
  grid-area: b;
  background-image: linear-gradient(163deg, white 0%, #fff 100%);
  border-radius: 20px;
  transition: all .3s;
}

.complemento-tecnologia:nth-child(3){
  grid-area: c;
  background-image: linear-gradient(163deg, white 0%, #fff 100%);
  border-radius: 20px;
  transition: all .3s;
}

.complemento-remodelacion:nth-child(4){
  grid-area: d;
  background-image: linear-gradient(163deg, white 0%, #fff 100%);
  border-radius: 20px;
  transition: all .3s;
}

.complemento-integracion:nth-child(5){
  grid-area: e;
  background-image: linear-gradient(163deg, white 0%, #fff 100%);
  border-radius: 20px;
  transition: all .3s;
}

.complemento-item-contorno{
  background-color: #0F787E;
 transition: all .2s;
 height: 100%;
}

.complemento-item-contorno:hover {
  transform: scale(0.98);
  border-radius: 20px;
 }

 .DescripcionComplemento{
  text-align: justify;
  color: #fff;
  margin: 30px;
 }

 .TituloComplemento{
  text-align: center;
  color: #fff;
 }