.header {
    box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
    position: relative;
    width: 100%;
    height: 15em;
    overflow: hidden;
    background-color: #0F787E;
    position: absolute;
  }

.Titulo{
    text-align: justify;
    font-size: 20px;
    position: relative;
    top: 2.6em;
    color: rgb(255, 255, 255);
    font-weight: 600;
    margin: 2em;
    padding-bottom: 2em;
}

.Descripcion{
    font-size: 14px;
    font-weight: 100;
    position: relative;
    top: 1em;
    text-align: justify;
    color: white;
    width: 60%;
}

.card-compra {
    position: absolute;
    left: 75%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 20%;
    height: 32em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #0F787E;
    padding: 1.5rem;
    top: 70%;
    box-shadow: -20px 20px 10px rgba(0,0,0,.5);
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .header-compra {
    display: flex;
    flex-direction: column;
  }
  
  .title-compra {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: #fff
  }
  
  .price-compra {
    font-size: 3.75rem;
    line-height: 1;
    font-weight: 700;
    color: #fff
  }
  
  .desc {
    margin-top: 2.4vh;
    margin-bottom: 2.5vh;
    line-height: 1.625;
    color: rgb(255, 255, 255);
  }
  
  .lists {
    margin-bottom: 1.5rem;
    flex: 1 1 0%;
    color: rgb(255, 255, 255);
  }
  
  .lists .list {
    margin-bottom: 0.5rem;
    display: flex;
  }
  
  .lists .list svg {
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
    margin-right: 0.5rem;
    color: #0A5458;
  }
  
  .action {
    text-decoration: none;
    background-color: #0A5458;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .action:hover {
    background-color: #19A7AF;
    transform: scale(1.1);
  }

  .main-aprendizaje{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 2em;
    width: 70%;
    margin: 30px;
  }
  
  .card-aprendizaje {
    height: 100%;
    width: 100%;
    background: #0F787E;
    transition: .4s ease-in-out;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .heading-aprendizaje {
    position: relative;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
    margin: 10px;
    transition: .4s ease-in-out;
  }
  
  .details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    color: #fff;
    font-size: 0.6em;
    padding: 1.5em;
    padding-left: 2em;
    transition: .4s ease-in-out;
  }

  .aprendizaje-lista {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Crea dos columnas */
    grid-gap: 1em; /* Espacio entre los elementos */
    list-style: none;
    padding-left: 0;
  }

  /* Contenedor principal del acordeón */
.accordion {
  width: 95%;
  margin: 30px;
  background-color: #0A5458;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Estilo de cada elemento del acordeón */
.accordion-item{
  background-color: #0A5458;
  border: 2px solid #19A7AF;
}

/* Estilo del encabezado o título del acordeón */
.accordion-item .link {
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0A5458;
  transition: background-color 0.3s ease;
}

/* Cuando el acordeón está abierto, cambia el color de fondo */
.accordion-item.open .link {
  background-color: #19A7AF;
}

/* Estilo de los íconos (flechas) */
.accordion-item .link i {
  transition: transform 0.3s ease;
}

/* Rotación del ícono cuando el acordeón está abierto */
.accordion-item.open i.fa-chevron-down {
  transform: rotate(180deg);
}

/* Submenú del acordeón (contenido) */
.accordion .submenu {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  background-color: #0A5458;
  padding-left: 15px;
}

/* Mostrar el submenú cuando se abre */
.accordion .submenu.show {
  max-height: 200px; /* Ajusta según el contenido */
  opacity: 1;
}

/* Texto dentro del submenú */
.accordion .submenu p {
  margin: 10px 0;
  color: #fff;
}

li{
  color: #fff;
}

/* Ajustes adicionales para el texto del título y la descripción */
.accordion-titulo {
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
}

.accordion-clases {
  font-size: 0.9em;
  color: #fff;
}

.accordion-description {
  font-size: 0.95em;
  color: #fff;
}

.temario-list{
  color: #fff;
  list-style-type: none;
}

  .main-container{
    position: relative;
  }

  .main-descripcion-contenido{
    position: relative;
    align-items: center;
    width: 95%;
  }
  
  .card-descripcion-contenido {
    position: relative;
    height: 100%;
    width: 100%;
    background: #0F787E;
    transition: .4s ease-in-out;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 30px;
  }
  
  .heading-descripcion-contenido {
    position: relative;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
    margin: 30px;
    transition: .4s ease-in-out;
    width: 80%;
  }

  .listaAprendizaje{
    font-size: 18px;
    width: 100%;
  }


.svg-icon {
  width: 25px; /* Ajusta el tamaño según sea necesario */
  height: 25px;
  margin: 10px;
}

.details-descripcion{
  margin: 30px;
  font-size: 17px;
}

@media only screen and (max-width: 1400px) {
  .card-compra {
    position: relative;
    left: 0%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 95%;
    height: 32em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #0F787E;
    padding: 1.5rem;
    top: 0%;
    box-shadow: -20px 20px 10px rgba(0,0,0,.5);
    justify-content: center;
    align-items: center;
    margin: 30px;
  }

  .main-aprendizaje{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 2em;
    width: 95%;
    margin: 30px;
  }
}