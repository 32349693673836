.active {
  background-color: #0A5458;
}

.nav-background.open {
  opacity: 1;
  visibility: visible;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0F787E;
  color: #ffffff;
  position: relative;
  top: 0;
  font-family: 'Poppins', sans-serif;
  height: 80px;
}

nav .title {
  font-size: 2rem;
  margin: 15%;
  text-decoration: none;
  color: white;
}

/* Menú desplegable */
nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; /* Para versión escritorio */
  background-color: #0F787E;
  opacity: 1;
  visibility: visible;
  height: auto;
  transform: none;
  transition: none;
}

nav ul li {
  list-style: none;
  margin: 20px 0;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  position: relative; /* Necesario para el uso de ::after */
  transition: color 0.3s ease;
  cursor: pointer;
}

/* Solo aplicar la animación en pantallas grandes */
@media (min-width: 769px) {
  nav ul li a::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px; /* Tamaño de la línea */
    background-color: white; /* Color de la línea */
    transform: scaleX(0); /* Línea escondida inicialmente */
    transform-origin: right; /* Animación empieza desde la derecha */
    transition: transform 0.3s ease; /* Suaviza la transición */
  }

  nav ul li a:hover::after {
    transform: scaleX(1); /* Muestra la línea al hacer hover */
    transform-origin: left; /* La línea aparece de izquierda a derecha */
  }
}

/* Menú hamburguesa */
nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 769px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    top: 100%;
    background-color: #0F787E;
    position: absolute;
    width: 100%;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s;
    height: 0;
    overflow: hidden;
    z-index: 10;
  }

  nav ul.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
    height: auto;
  }

  nav ul.closing {
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  /* Desactivar la animación en móviles */
  nav ul li a::after {
    content: none;
  }

  nav .title {
    font-size: 2rem;
    margin: 2%;
  }
}

/* Añadir la clase has-submenu para manejar el hover */
.has-submenu {
  position: relative;
}

.submenu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #0F787E;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1000;
  width: max-content;
  opacity: 0; /* Inicialmente invisible */
  visibility: hidden; /* No visible hasta hover */
  transform: translateY(-10px); /* Desplazado hacia arriba para la animación */
  transition: all 0.3s ease-in-out; /* Suaviza la transición de visibilidad, opacidad y posición */
}

.has-submenu:hover .submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Baja el submenú a su posición original */
}

.submenu li {
  padding: 0.75rem 1rem;
}

.submenu li a {
  display: block;
  color: white;
  text-decoration: none;
}

.submenu li a:hover {
  background-color: #0F787E;
}

/* Ajustes para evitar que el submenú salga del viewport */
@media (min-width: 756px) {
  .submenu {
    left: auto;
    right: 0;
  }
}
