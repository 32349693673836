/* From Uiverse.io by vinodjangid07 */ 
.form_main {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(15, 120, 126);
    padding: 30px 30px 30px 30px;
    margin: auto;
    margin-top: 40px;
    border-radius: 30px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
  }

  /* Estilo del select */
.inputField {
  background-color: #0F787E; /* Cambia el color del fondo del select cerrado */
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 200px;
}

/* Estilo cuando el select está abierto (foco) */
.inputField:focus {
  background-color: #19A7AF; /* Cambia el fondo cuando está en foco */
  outline: none; /* Elimina el borde por defecto */
}

/* Para cambiar el color de las opciones del dropdown */
.inputField option {
  background-color: #19A7AF; /* Color de fondo de las opciones */
  color: white; /* Color del texto */
}

/* Estilo para que no se vea transparente al seleccionar */
.inputField option:checked {
  background-color: #19A7AF; /* Mantener el fondo en la opción seleccionada */
} 
  
  .heading {
    font-size: 2.5em;
    color: #ffffff;
    font-weight: 700;
    margin: 15px 0 30px 0;
  }
  
  .inputContainer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inputIcon {
    position: absolute;
    left: 10px;
  }
  
  .inputField {
    width: 100%;
    height: 7vh;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(10, 84, 88);
    border-radius: 30px;
    margin: 10px 0;
    color: rgb(255, 255, 255);
    font-size: .8em;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 30px;
  }
  
  .inputField:focus {
    outline: none;
    border-bottom: 2px solid rgb(25, 167, 175);
  }
  
  .inputField::placeholder {
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-weight: 500;
  }
  
  #button {
    position: relative;
    width: 100%;
    border: 2px solid #0a5458;
    background-color: #0a5458;
    height: 40px;
    color: white;
    font-size: .8em;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 30px;
    margin: 10px;
    cursor: pointer;
    overflow: hidden;
  }
  
  #button::after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.253);
    height: 100%;
    width: 150px;
    top: 0;
    left: -200px;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 100px;
    filter: blur(10px);
    transition-duration: .5s;
  }
  
  #button:hover::after {
    transform: translateX(500%);
    transition-duration: .5s;
  }

  /* Para Chrome, Safari, Edge y otros navegadores basados en Webkit */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}